<template>
  <div
    id="app-mobile"
    class="box"
  >
  <!-- @submit="onSubmit" -->
    <van-form
      :label-width="110"
      label-align="right"
    >
      <div class="boxTop">
        <div class="boxTopTitle">
          <div class="title">
            {{ info.title }}
          </div>
          <div class="remark">
            {{ info.remark }}
          </div>
        </div>
        <div
          class="boxTopFrom"
          v-if="this.info.toRater"
        >
          <van-field
            readonly
            required
            clickable
            name="object"
            :value="baseInfo.object"
            label="请选择评价对象"
            placeholder="请选择评价对象"
            @click="showObject = true"
            right-icon="arrow"
            :rules="[{ required: true }]"
          />
          <van-popup
            v-model="showObject"
            position="bottom"
          >
            <van-picker
              show-toolbar
              :columns="columns"
              @confirm="onConfirmObject"
              @cancel="showObject = false"
              class="object"
              ref="picker"
            />
          </van-popup>
          <van-field
            v-model="baseInfo.department"
            name="department"
            label="所属部门"
            disabled
          />
        </div>
      </div>
      <div
        class="boxContent"
        v-for="(item, ind) in list"
        :key="ind"
      >
        <div class="contentTitle">{{ item.title }}</div>
        <div
          v-if="item.type !== 4"
          class="contentTip"
        >
          {{
            item.subjectJson.sort === "ascending"
              ? item.subjectJson.startNum
              : item.subjectJson.startNum + item.subjectJson.dimensionality - 1
          }}分表示{{ item.subjectJson.polarizationStart }}，{{
            item.subjectJson.sort === "ascending"
              ? item.subjectJson.startNum + item.subjectJson.dimensionality - 1
              : item.subjectJson.startNum
          }}分表示{{ item.subjectJson.polarizationEnd }}
        </div>
        <div
          v-if="item.type === 4"
          class="text-container"
        >
          <el-input
            v-model="item.subjectJson.value"
            type="textarea"
            @change="textChange($event, item)"
          ></el-input>
        </div>
        <div
          class="contentAnswer"
          v-for="(v, d) in item.subjectJson.lines"
          :key="d"
        >
          <div>{{ v.title }}</div>
          <div class="contentRate">
            <van-field
              name="rate"
              required
              :rules="[{ validator, message: '请选择' }]"
              class="rate"
            >
              <template #input>
                <van-rate
                  :class="'rate-' + item.id"
                  v-model="v.value"
                  size="25"
                  color="#FF9D26"
                  :count="item.subjectJson.dimensionality"
                  @change="setRate($event, item.subjectJson.lines, item.id)"
                />
              </template>
            </van-field>
          </div>
        </div>
      </div>
      <div class="boxBtn">
        <van-button
          class="button"
          round
          block
          type="primary"
          @click="onSubmit"
          native-type="submit"
        >
          提交答案
        </van-button>
        <van-button
          class="button"
          round
          block
          type="primary"
          @click="onNext"
          native-type="submit"
          v-if="columns.length > 1"
        >
          下一个
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import {
  subjectQueryByQuestionnaireIdList,
  questionnaireFindById,
  userQueryByUserIds,
  questionnaireReplyAddOrUpdate,
  questionnaireSubjectList,
  questionnaireReplyCompleteTime,
  questionnaireSubjectAddOrUpdate,
} from "@/apis/mobileApi";
import { getToken, getDevice } from "@/utils/cookie";
export default {
  name: "questionContent",
  data() {
    return {
      baseInfo: {
        object: "",
        department: "",
      },
      columns: [],
      columnsObject: [],
      showObject: false,
      list: [],
      info: {},
      users: {},
      id: "",
      replyId: "",
      subjectList: [],
    };
  },
  computed: {},
  mounted() {
    this.id = this.$route.params.id;
    this.replyId = this.$route.params.replyId;
    this.getList();
    this.getTitle();
    if (this.replyId === "0") {
      this.getReply();
    } else {
      this.getSubjectList();
    }
  },
  methods: {
    validator(val) {
      return val > 0;
    },
    setRate(value, lines, id) {
      if (lines.every((o) => o.value > 0)) {
        const item = {
          subjectId: id,
          answerJson: JSON.stringify(lines),
          questionnaireId: this.id,
          questionnaireReplyId: this.replyId,
        };
        // console.log(item);
        // console.log(id);
        this.changeAddOrUpdate(item);
      }
    },
    textChange(value, data) {
      const item = {
        subjectId: data.id,
        answerJson: value,
        questionnaireId: this.id,
        questionnaireReplyId: this.replyId,
      };
      this.changeAddOrUpdate(item);
    },
    changeAddOrUpdate(item) {
      const obj = this.subjectList.find((v) => {
        return v.subjectId === item.subjectId;
      });
      if (obj) {
        var subjectId = obj.id;
      }
      questionnaireSubjectAddOrUpdate({ ...item, id: subjectId }).then(() => {
        this.getSubjectList();
      });
    },
    onConfirmObject(value, index) {
      this.baseInfo.object = value;
      this.showObject = false;
      const items = this.columnsObject[index];
      this.baseInfo.department = items.department;
      const item = {
        ...this.baseInfo,
        subjectId: this.users.id,
        answerJson: items.username,
        questionnaireId: this.id,
        questionnaireReplyId: this.replyId,
      };
      this.changeAddOrUpdate(item);
    },
    onSubmit() {
      questionnaireReplyCompleteTime({ id: this.replyId }).then(() => {
        // window.location.href = `/mobile/home`;
        this.$router.push({
          name: "mobile-end-page",
          query: {
            uid: this.info.uid,
            username: getToken(),
            qid: this.id,
          },
        });
      });
    },
    onNext() {
      questionnaireReplyCompleteTime({ id: this.replyId }).then(() => {
        location.href = `/mobile/questionContent/${this.id}/${0}`
      });
    },
    getList() {
      subjectQueryByQuestionnaireIdList({
        username: getToken(),
        questionnaireId: this.id,
      }).then(({ data }) => {
        const items = [];
        data.forEach((item) => {
          item.subjectJson = JSON.parse(item.subjectJson);
          if (item.type !== 3) {
            items.push(item);
          } else {
            this.users = item;
            this.getColumns();
          }
        });
        this.list = items;
        items.forEach((subject) => {
          this.subjectList.forEach((item) => {
            if (item.subjectId === subject.id) {
              subject.subjectJson.lines = JSON.parse(item.answerJson);
            }
          });
        });
      });
    },
    getTitle() {
      questionnaireFindById({ id: this.id }).then(({ data }) => {
        this.info = data;
      });
    },
    getColumns() {
      userQueryByUserIds({
        userIds: this.users.subjectJson.user,
      }).then(({ data }) => {
        if (data) {
          const items = data;
          this.columnsObject = data;
          items.forEach((item) => {
            this.columns.push(item.nickname);
            this.baseInfo.object = items[0].nickname
          });
          this.onConfirmObject(items[0].nickname, 0)
        }
        const aJ = this.subjectList.map((item) => item.answerJson)[0];
        const o = data.find((item) => item.username === aJ);
        if (o) {
          this.baseInfo.object = o.nickname;
          this.baseInfo.department = o.department;
        }
      });
    },
    getReply() {
      questionnaireReplyAddOrUpdate({
        equipment: getDevice(),
        nickname: getToken(),
        questionnaireId: this.id,
        username: getToken(),
      }).then(({ data }) => {
        console.log(data);
        this.replyId = data.id;
      });
    },
    getSubjectList() {
      questionnaireSubjectList({
        questionnaireReplyId: this.replyId,
      }).then(({ data }) => {
        this.subjectList = data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$room: 1020 / 375;
.text-container {
  margin-top: 15px;
}
.box {
  // width: 100vw;
  height: calc(100vh - 50px * $room);
  background: #f4f5f8;
}
.boxTop {
  background: #fff;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .boxTopTitle {
    width: 90vw;
    border-bottom: 1px solid #ccc;
    padding: 15px * $room 0;
    .title {
      font-size: 16px * $room;
      padding-bottom: 10px * $room;
    }
    .remark {
      font-size: 14px * $room;
      color: #636363;
    }
  }
  .boxTopFrom {
    width: 100vw;
    padding: 10px * $room 0;
    ::v-deep .van-cell {
      font-size: 5.5px * $room;
    }
    ::v-deep .van-cell::after {
      border-bottom: none;
    }
  }
}
.object,
.department {
  ::v-deep .van-picker-column {
    font-size: 24px * $room;
  }
  ::v-deep .van-picker__toolbar {
    height: 50px * $room;
  }
  ::v-deep .van-picker__cancel {
    font-size: 8px * $room;
  }
  ::v-deep .van-picker__confirm {
    font-size: 20px * $room;
  }
}
.boxContent {
  background: #fff;
  margin-top: 10px * $room;
  padding: 20px * $room 15px * $room;
  font-size: 16px * $room;
  .contentTitle {
    font-size: 18px * $room;
  }
  .contentTip {
    font-size: 14px * $room;
    padding: 20px * $room 0;
    line-height: 24px * $room;
    color: #636363;
  }
  .contentRate {
    margin-bottom: 10px * $room;
  }
}
.boxBtn {
  margin-top: 50px * $room;
  display: flex;
  justify-content: space-around;
  .button {
    height: 40px * $room;
    width: 40%;
    font-size: 20px * $room;
    border-radius: 5px * $room;
    background: #008755;
    margin-bottom: 50px * $room;
  }
}
.rate {
  ::v-deep .van-rate {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
</style>
